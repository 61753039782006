import React, { useEffect } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import './css/Home.css'
import ReactPlayer from 'react-player'
import Button from 'react-bootstrap/Button'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { AuditoriumVideoPlay, HelpDeskVideoPlay, ExhibitionVideoPlay } from '../Actions';
import { trackdata } from '../Helper/Dranzer';
import { isBrowser } from 'react-device-detect';
export const Homepage = ({ commonData }) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const auditPlay = useSelector(state => state.AuditoriumVideoPlay);
    const helpPlay = useSelector(state => state.HelpDeskVideoPlay);
    const exhibitionPlay = useSelector(state => state.ExhibitionVideoPlay);
    const user = useSelector(state => state.userDetails);
    const phptoken = useSelector(state => state.phpToken);

    document.title = "PureFood Expo";
    const goAuditorium = () => {
        history.push('/auditoriumhall');
        dispatch(AuditoriumVideoPlay(false));
    }
    const goHelpDesk = () => {
        history.push('/helpdesk');
        dispatch(HelpDeskVideoPlay(false));
    }
    const goExhibitionDesk = () => {
        history.push('/exhibition');
        dispatch(ExhibitionVideoPlay(false));
    }
//tracker code
    useEffect(() => {
         trackdata(commonData.siteurl,commonData.AuthorizeKey,phptoken, "Home", "", "", user.accountid, "")
    }, [])

    
    
      
    return (
        <div className="home-block">
            <div id="homepage-img-block" >
                <img className="img-responsive"
                    src={`/images/${commonData.sitename}/lobby.jpg`}
                    alt="homelobby" />
            </div>

            <div className='bg-black' id="audiorium-vid-view" style={{ display: "none" }}>
                <div id="vid-skipbutton" >
                    <Button onClick={() => goAuditorium()} variant="primary" className='btnOrange' >Skip Walkthrough</Button>
                </div>
                <ReactPlayer  playsinline= 'true' playing={auditPlay} onEnded={() => goAuditorium()} url={`/video/${commonData.sitename}/auditorium.mp4`} width="100%" height="100%" />  
                {/* height={isBrowser ? window.innerHeight - 70 : window.innerHeight} */}
            </div>
            
            <div className='bg-black' id="helpdesk-vid-view" style={{ display: "none" }}>
                <div id="vid-skipbutton" >
                    <Button onClick={() => goHelpDesk()} variant="primary" className='btnOrange'>Skip Walkthrough</Button>
                </div>
                <ReactPlayer  playsinline= 'true' playing={helpPlay} onEnded={() => goHelpDesk()} url={`/video/${commonData.sitename}/inquiry_counter.mp4`} width="100%" height="100%"  />
            </div>

            <div className='bg-black' id="exhibition-vid-view" style={{ display: "none" }}>
                <div id="vid-skipbutton" >
                    <Button onClick={() => goExhibitionDesk()} variant="primary" className='btnOrange' >Skip Walkthrough</Button>
                </div>
                <ReactPlayer  playsinline= 'true' playing={exhibitionPlay} onEnded={() => goExhibitionDesk()} url={`/video/${commonData.sitename}/exhibition.mp4`} width="100%" height="100%"   />
            </div>

        </div>
    )
}
