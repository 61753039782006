import React, { useState, useEffect, useRef } from 'react'
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import axios from 'axios';
import { PhpToken, UserDetails } from '../Actions';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ToastNotify } from '../Actions';
import { Pageloader } from '../Components/Pageloader';
import serialize from 'form-serialize';
import './css/RegisterPage2.css'
import { Preloader } from '../Components/Preloader';
import { MultiSelect } from "react-multi-select-component";

const RegisterPage2 = ({ commonData, toast, reference }) => {
    const loginleft = {
        background: "#fff", //"url(/loginleftimage.jpg)",
        minHeight: window.innerHeight,
        backgroundSize: "100% 100%"
    }

    document.title = "Register Page";
    const phptoken = useSelector(state => state.phpToken);
    const user = useSelector(state => state.userDetails);
    const history = useHistory();
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const regPass = useRef({});
    regPass.current = watch("regPass", "");
    const [submitloader, setSubmitLoader] = useState(false);
    useEffect(() => {
        // setValue("regMcode", "91");
        setValue("country", "India");
        setValue("state", "GUJARAT");
    }, [])
    const [Designation, setDesignation] = useState([]);
    const [Country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [industry, setindustry] = useState([]);
    const [tags, settags] = useState([]);
    const [fieldList, setfieldList] = useState([])
    const [pageloader, setpageloader] = useState(true);
    useEffect(() => {
        async function getRegisterSettingDetail() {
            let url = commonData.siteurl + '/expo_access_api/getregisterfieldlist/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                user: user.accountid
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    if (res.data[0].DATA.fileds) {
                        setfieldList(res.data[0].DATA.fileds);
                    }
                    if (res.data[0].DATA.designation) {
                        setDesignation(res.data[0].DATA.designation);
                    }
                    if (res.data[0].DATA.country) {
                        setCountry(res.data[0].DATA.country);
                    }
                    if (res.data[0].DATA.state) {
                        setState(res.data[0].DATA.state);
                    }
                    if (res.data[0].DATA.industry) {
                        setindustry(res.data[0].DATA.industry);
                    }
                    if (res.data[0].DATA.tags) {
                        settags(res.data[0].DATA.tags);
                    }
                    setpageloader(false)
                }
            }
        }

        getRegisterSettingDetail();
    }, []);
    //change state as per country
    const changestate = (e) => {

        async function getStates() {
            let url = commonData.siteurl + '/expo_access_api/getstatelist/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                COUNTRY: e.target.value
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    if (res.data[0].DATA.state) {
                        setState(res.data[0].DATA.state);
                    }

                } else {
                    dispatch(ToastNotify(true, "ERROR", res.data[0].MSG, "danger"));
                    setState([]);
                }
            }
        }

        getStates();
    }

    const [WizardDwtails, setWizardDwtails] = useState([])
    const [wizardSteps, setwizardSteps] = useState(1)
    const onSubmit = (data) => {
        setSubmitLoader(true);
        async function submitData() {

            let url = commonData.siteurl + '/expo_access_api/registerUserExpo/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const form = document.querySelector('#register-form');

            const obj = serialize(form, { hash: true });
            const fd = new FormData();

            Object.keys(obj).forEach(function (key) {
                fd.append(key, (obj[key]));
            });

            fd.append("AUTHORIZEKEY", (commonData.AuthorizeKey));
            if (reference) {
                fd.append("reference", (reference));
            }
            const res = await axios.post(url, fd, headers);
            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    if (res.data[0].DATA) {
                        localStorage.setItem("redirectuser", res.data[0].DATA.redirect);
                        setWizardDwtails(res.data[0].DATA);
                        setwizardSteps(2);
                        toast("SUCCESS", res.data[0].MSG, "success");
                        setValue("con_add1", "");
                        setValue("con_city", "");
                        setValue("con_pincode", "");
                        setValue("regCompName", "");
                        toast("SUCCESS", res.data[0].MSG, "success");
                    }
                } else {
                    toast("ERROR", res.data[0].MSG, "danger");
                }
                setSubmitLoader(false);
            }
        }
        submitData();
    }
    //second step
    const [selectedTag, setSelectedTag] = useState([]);
    const onSubmitwizard = (data) => {
        setSubmitLoader(true);
        async function submitData() {
            if (data.profileimg.length > 0) {
                if (data.profileimg[0].size > 2097152) {
                    dispatch(ToastNotify(true, "ERROR", "Filesize should be less than 2MB", "danger"));
                    setSubmitLoader(false);
                    return false;

                }
                if (!(data.profileimg[0].type === 'image/jpeg' || data.profileimg[0].type === 'image/jpg' || data.profileimg[0].type === 'image/gif' || data.profileimg[0].type === 'image/png' || data.profileimg[0].type === 'image/pjpeg')) {
                    dispatch(ToastNotify(true, "ERROR", "Invalid file type only JPEG/PNG/JPG files allowed", "danger"));
                    setSubmitLoader(false);
                    return false;

                }
            }

            let url = commonData.siteurl + '/expo_access_api/registerUserExpoWizard/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const form = document.querySelector('#register-form2');

            const obj = serialize(form, { hash: true });
            const fd = new FormData();

            Object.keys(obj).forEach(function (key) {
                fd.append(key, (obj[key]));
            });
            if (data.profileimg.length > 0) {
                fd.append('profileimg', data.profileimg[0], data.profileimg[0].name);
            }
            if(selectedTag){
                fd.append("tags", (JSON.stringify(selectedTag)));
            }
            fd.append("AUTHORIZEKEY", (commonData.AuthorizeKey));
            fd.append("contact", (WizardDwtails.contact));
            const res = await axios.post(url, fd, headers);
            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    if (res.data[0].DATA) {
                        localStorage.setItem("redirectuser", res.data[0].DATA.redirect);
                        if (res.data[0].DATA.redirect == "Y") {

                            // async function processlogin() {

                            //     const username = WizardDwtails.user;
                            //     const password = WizardDwtails.usershort;
                            //     const usertype = "v";
                            //     const remember = true;

                            //     let otpurl = commonData.siteurl + '/virtualexpo_api/verifyLogin/';
                            //     const headers = {
                            //         'content-type': 'multipart/form-data'
                            //     };
                            //     const loginres = await axios.post(otpurl, {
                            //         AUTHORIZEKEY: commonData.AuthorizeKey,
                            //         username: username,
                            //         password: password,
                            //         usertype: usertype
                            //     }, headers);

                            //     if (loginres.data[0].STATUS == "SUCCESS") {
                            //         if (remember === true) {
                            //             localStorage.setItem("eusername", username);

                            //             if (password !== "null") {
                            //                 localStorage.setItem("epassword", password);
                            //             }
                            //         }
                            //         toast("SUCCESS", loginres.data[0].MSG, "success");
                            //         setSubmitLoader(false);
                            //         if (loginres.data[0].DATA.expo_portal_disallow == "Y") {
                            //             history.push("/hold");

                            //         } else {
                            //             dispatch(PhpToken(loginres.data[0].PHPTOKEN));
                            //             dispatch(UserDetails(loginres.data[0].DATA));
                            //             if (localStorage.getItem("linklasturl")) {
                            //                 var urlSave = localStorage.getItem("linklasturl")
                            //                 localStorage.setItem("linklasturl", "");
                            //                 // window.open(urlSave);
                            //                 history.push(urlSave);
                            //             } else {
                            //                 history.push("/home");
                            //             }

                            //         }

                            //     } else {
                            //         toast("Error", loginres.data[0].MSG, "danger");
                            //         setSubmitLoader(false);
                            //     }
                            // }
                            //processlogin();
                            generateotp();
                        } else {
                            toast("SUCCESS", res.data[0].MSG, "success");
                        }
                        setSubmitLoader(false);
                    }
                } else {
                    toast("SUCCESS", res.data[0].MSG, "success");
                }

            }
        }
        submitData();
    }
    //wizard steps as follow

    const [keyOT, setkeyOT] = useState("")
    const generateotp = () => {
        setwizardSteps(3);
        async function sendotp() {
            setSubmitLoader(true);
            const contact = WizardDwtails.contact;
            let otpurl = commonData.siteurl + '/expo_access_api/verifyGenerateOTP/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const loginres = await axios.post(otpurl, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                contact: contact
            }, headers);

            if (loginres.data[0].STATUS == "SUCCESS") {
                if (loginres.data[0].DATA) {
                    setkeyOT(loginres.data[0].DATA);

                }
                toast("SUCCESS", loginres.data[0].MSG, "success");
                setSubmitLoader(false);

            } else {
                toast("Error", loginres.data[0].MSG, "danger");
                setSubmitLoader(false);
            }
        }
        sendotp();

    }
    const onVerifyOTP = (data) => {

        async function verifyotp() {
            setSubmitLoader(true);
            const contact = WizardDwtails.contact;
            const number = data["verifyOTP"];

            let otpurl = commonData.siteurl + '/expo_access_api/verifyRegisterOTP/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const loginres = await axios.post(otpurl, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                contact: contact,
                key: keyOT,
                verifyOTP: number
            }, headers);

            if (loginres.data[0].STATUS == "SUCCESS") {
                skipRegProcess();
                toast("SUCCESS", loginres.data[0].MSG, "success");
                setSubmitLoader(false);

            } else {
                toast("Error", loginres.data[0].MSG, "danger");
                setSubmitLoader(false);
            }
        }
        verifyotp();
    }
    const skipRegProcess = () => {

        async function processlogin() {

            const username = WizardDwtails.user;
            const password = WizardDwtails.usershort;
            const usertype = "v";
            const remember = true;

            let otpurl = commonData.siteurl + '/virtualexpo_api/verifyLogin/';
            const headers = {
                'content-type': 'multipart/form-data'
            };
            const loginres = await axios.post(otpurl, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                username: username,
                password: password,
                usertype: usertype
            }, headers);

            if (loginres.data[0].STATUS == "SUCCESS") {
                if (remember === true) {
                    localStorage.setItem("eusername", username);

                    if (password !== "null") {
                        localStorage.setItem("epassword", password);
                    }
                }
                toast("SUCCESS", loginres.data[0].MSG, "success");
                setSubmitLoader(false);
                if (loginres.data[0].DATA.expo_portal_disallow == "Y") {
                    history.push("/hold");

                } else {
                    dispatch(PhpToken(loginres.data[0].PHPTOKEN));
                    dispatch(UserDetails(loginres.data[0].DATA));
                    if (localStorage.getItem("linklasturl")) {
                        var urlSave = localStorage.getItem("linklasturl")
                        localStorage.setItem("linklasturl", "");
                        // window.open(urlSave);
                        history.push(urlSave);
                    } else {
                        history.push("/home");
                    }

                }

            } else {
                toast("Error", loginres.data[0].MSG, "danger");
                setSubmitLoader(false);
            }
        }
        if (WizardDwtails.redirect == "Y") {
            processlogin();
        } else {
            history.push("/home");
        }

    }
    const d = new Date();
    const year = d.getFullYear();
    const homepage = () => {
        history.push("/");
    }
    return (
        <div>
            <div className="col-12 registerBlock2" style={loginleft}>
                <div className="col-12 row login-view ">
                    <div className="col-md-6 leftSide" style={{ background: "url('/images/global/regbg.png')", backgroundSize: 'cover' }}>
                        <div className="col-12 rightBlock text-center" >
                            <div className="centerBlock">
                                <img onClick={() => { homepage() }} className="imgLogo img-responsive cursor-pointer" src="/images/global/regLogo.png" alt='logo_image' />
                                <div className="reisterRightView text-center py-2">
                                    <h2 className='orangeColor py-2'>Already Registered?</h2>
                                    <div className="col-12">
                                        <Link to="/login">
                                            <Button className="btn-lg btnOrange loginBtn btn btn-primary" variant="primary" type="submit">
                                                Login
                                            </Button>
                                        </Link>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="col-md-6 rightSide">
                        {
                            pageloader
                                ? <Pageloader />
                                :
                                <div className="registerViewBlock1 py-2">
                                    {wizardSteps == 1
                                        ?
                                        <Form id="register-form" className="col-12 row m-0" onSubmit={handleSubmit(onSubmit)}>
                                            <h2 className='text-center fw-bold py-2'> <span className="orangeColor">Visitor</span> <span className="orangeColor">Registration</span></h2>
                                            <Form.Group className="mt-2 col-md-12" controlId="regName">
                                                <FloatingLabel controlId="floatingInput" label="Name*" className="mb-2" >
                                                    <Form.Control type="text" placeholder="please enter your number" {...register('regName', { required: "Name is required", pattern: { value: /^[A-Za-z ]+$/i, message: 'please enter only text' } })} />
                                                </FloatingLabel>
                                                <Form.Text className="text-muted">
                                                    {errors.regName && <p className="text-danger">{errors.regName.message}</p>}
                                                </Form.Text>
                                            </Form.Group>



                                            <Form.Group className="mt-2 col-md-12" controlId="regMobile">
                                                <FloatingLabel controlId="floatingInput" label="Mobile Number* " className="mb-2" >
                                                    <Form.Control type="text" placeholder="please enter your mobile number"
                                                        {...register('regMobile', {
                                                            required: "Mobile number is required",
                                                            maxLength: { value: 13, message: 'Mobile number must be less than 13 characters' },
                                                            minLength: { value: 6, message: 'Mobile number must be more than 6' },
                                                            pattern: { value: /^[0-9]+$/i, message: 'please enter only number' }
                                                        })} />
                                                </FloatingLabel>
                                                <Form.Text className="text-muted">
                                                    {errors.regMobile && <p className="text-danger">{errors.regMobile.message}</p>}
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group className={`mt-2 col-md-12 ${fieldList["email"]["hidden"] == "Y" ? "dn" : ""}`} controlId="regEmail">
                                                <FloatingLabel controlId="floatingInput" label={`Email${fieldList["email"]["required"] == "Y" ? "*" : ""}`} className="mb-2" >
                                                    <Form.Control type="email" placeholder="Please enter your email" {...register('regEmail', { required: { value: fieldList["email"]["required"] == "Y" && fieldList["email"]["hidden"] == "N" ? true : false, message: 'Email is required' } })} />
                                                </FloatingLabel>
                                                <Form.Text className="text-muted">
                                                    {errors.regEmail && <p className="text-danger">{errors.regEmail.message}</p>}
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group className="mt-2 col-md-12" controlId="regPass">
                                                <FloatingLabel controlId="floatingInput" label="Password*" className="mb-2" >
                                                    <Form.Control type="password" placeholder="please enter your password"
                                                        {...register('regPass', {
                                                            required: "Password number is required"
                                                        })} />
                                                </FloatingLabel>
                                                <Form.Text className="text-muted">
                                                    {errors.regPass && <p className="text-danger">{errors.regPass.message}</p>}
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group className="mt-2 col-md-12" controlId="regCPass">
                                                <FloatingLabel controlId="floatingInput" label="Confirm Password*" className="mb-2" >
                                                    <Form.Control type="password" placeholder="please enter your confirm password"
                                                        {...register('regCPass', {
                                                            validate: value =>
                                                                value === regPass.current || "The passwords do not match"
                                                        })} />
                                                </FloatingLabel>
                                                <Form.Text className="text-muted">
                                                    {errors.regCPass && <p className="text-danger">{errors.regCPass.message}</p>}
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group className={`mt-2 col-md-12 ${fieldList["country"]["hidden"] == "Y" ? "dn" : ""}`} controlId="country">
                                                <FloatingLabel controlId="floatingInput" label={`Country${fieldList["country"]["required"] == "Y" ? "*" : ""}`} className="mb-2" >
                                                    <Form.Select aria-label="Default select example" {...register('country', { required: { value: fieldList["country"]["required"] == "Y" && fieldList["country"]["hidden"] == "N" ? true : false, message: 'Country is required' } })} onChange={(e) => { changestate(e) }}>
                                                        <option value="">Select</option>
                                                        {Country
                                                            ?
                                                            [Country.map((C) => (
                                                                <option key={`country_${C.Country.country_code}`} value={C.Country.country_name}>{C.Country.country_name}</option>
                                                            ))]
                                                            : ""
                                                        }
                                                    </Form.Select>
                                                </FloatingLabel>
                                                <Form.Text className="text-muted">
                                                    {errors.country && <p className="text-danger">{errors.country.message}</p>}
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group className={`mt-2 col-md-12 ${fieldList["state"]["hidden"] == "Y" ? "dn" : ""}`} controlId="state">
                                                <FloatingLabel controlId="floatingInput" label={`State${fieldList["state"]["required"] == "Y" ? "*" : ""}`} className="mb-2" >
                                                    <Form.Select aria-label="Default select example" {...register('state', { required: { value: fieldList["state"]["required"] == "Y" && fieldList["state"]["hidden"] == "N" ? true : false, message: 'State is required' } })}>
                                                        <option value="">Select</option>
                                                        {State
                                                            ?
                                                            [State.map((S) => (
                                                                <option key={`state_${S.State.state_name}`} value={S.State.state_name}>{S.State.state_name}</option>
                                                            ))]
                                                            : ""
                                                        }
                                                    </Form.Select>
                                                </FloatingLabel>
                                                <Form.Text className="text-muted">
                                                    {errors.state && <p className="text-danger">{errors.state.message}</p>}
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Text>
                                                By clicking Submit, you agree to our  <Link to="/terms-policy" target="_blank" className='blueColor'>Terms</Link>, <Link to="/privacy-policy" target="_blank" className='blueColor'>Privacy Policy</Link> and <Link to="/cookie-policy" target="_blank" className='blueColor'>Cookie Policy</Link>. You may receive SMS and EMAIL notifications from us.
                                            </Form.Text>

                                            <div className="mt-4 text-center col-12 ">
                                                <Button className="btn-lg btnBlue loginBtn btn btn-primary" type="submit" disabled={submitloader} >
                                                    Submit
                                                </Button>
                                            </div>
                                            <div className="text-center submitloader mt-3">
                                                {submitloader ?
                                                    <>
                                                        <Preloader />
                                                    </>
                                                    : ""
                                                }
                                            </div>
                                        </Form>
                                        :
                                        [wizardSteps == 2
                                            ?
                                            <Form id="register-form2" className="col-12 row m-0" onSubmit={handleSubmit(onSubmitwizard)}>
                                                <h2 className='text-center fw-bold py-2'> <span className="orangeColor">Profile Details</span> </h2>

                                                <Form.Group className={`mt-2 col-md-12 ${fieldList["compName"]["hidden"] == "Y" ? "dn" : ""}`} controlId="regCompName">
                                                    <FloatingLabel controlId="floatingInput" label={`Company Name${fieldList["compName"]["required"] == "Y" ? "*" : ""}`} className="mb-2" >
                                                        <Form.Control type="text" placeholder="please enter your number" {...register('regCompName', { required: { value: fieldList["compName"]["required"] == "Y" && fieldList["compName"]["hidden"] == "N" ? true : false, message: 'Company name is required' } })} />
                                                    </FloatingLabel>
                                                    <Form.Text className="text-muted">
                                                        {errors.regCompName && <p className="text-danger">{errors.regCompName.message}</p>}
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className={`mt-2 col-md-12 ${fieldList["industry"]["hidden"] == "Y" ? "dn" : ""}`} controlId="con_industry">
                                                    <FloatingLabel controlId="floatingInput" label={`Industry${fieldList["industry"]["required"] == "Y" ? "*" : ""}`} className="mb-2" >
                                                        <Form.Select aria-label="Default select example" {...register('con_industry', { required: { value: fieldList["industry"]["required"] == "Y" && fieldList["industry"]["hidden"] == "N" ? true : false, message: 'Industry is required' } })}>
                                                            <option value="">Select</option>
                                                            {industry
                                                                ?
                                                                [industry.map((i) => (
                                                                    <option key={`industry_${i.Industry.id}`} value={i.Industry.id}>{i.Industry.name}</option>
                                                                ))]
                                                                : ""
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    <Form.Text className="text-muted">
                                                        {errors.con_industry && <p className="text-danger">{errors.con_industry.message}</p>}
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group className={`mt-2 col-md-12 ${fieldList["designation"]["hidden"] == "Y" ? "dn" : ""}`} controlId="con_desig">
                                                    <FloatingLabel controlId="floatingInput" label={`Designation${fieldList["designation"]["required"] == "Y" ? "*" : ""}`} className="mb-2" >
                                                        <Form.Select aria-label="Default select example" {...register('con_desig', { required: { value: fieldList["designation"]["required"] == "Y" && fieldList["designation"]["hidden"] == "N" ? true : false, message: 'Designation is required' } })}>
                                                            <option value="">Select</option>
                                                            {Designation
                                                                ?
                                                                [Designation.map((D) => (
                                                                    <option key={`designation_${D.Designation.designation_id}`} value={D.Designation.designation_id}>{D.Designation.designation}</option>
                                                                ))]
                                                                : ""
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    <Form.Text className="text-muted">
                                                        {errors.con_desig && <p className="text-danger">{errors.con_desig.message}</p>}
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group className={`mt-2 col-md-12 ${fieldList["address"]["hidden"] == "Y" ? "dn" : ""}`} controlId="con_add1">
                                                    <FloatingLabel controlId="floatingInput" label={`Address${fieldList["address"]["required"] == "Y" ? "*" : ""}`} className="mb-2" >
                                                        <Form.Control type="text" placeholder="Please enter your address" {...register('con_add1', { required: { value: fieldList["address"]["required"] == "Y" && fieldList["address"]["hidden"] == "N" ? true : false, message: 'Address is required' } })} />
                                                    </FloatingLabel>
                                                    <Form.Text className="text-muted">
                                                        {errors.con_add1 && <p className="text-danger">{errors.con_add1.message}</p>}
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group className={`mt-2 col-md-12 ${fieldList["city"]["hidden"] == "Y" ? "dn" : ""}`} controlId="con_city">
                                                    <FloatingLabel controlId="floatingInput" label={`City${fieldList["city"]["required"] == "Y" ? "*" : ""}`} className="mb-2" >
                                                        <Form.Control type="text" placeholder="Please enter your email" {...register('con_city', { required: { value: fieldList["city"]["required"] == "Y" && fieldList["city"]["hidden"] == "N" ? true : false, message: 'City is required' } })} />
                                                    </FloatingLabel>
                                                    <Form.Text className="text-muted">
                                                        {errors.con_city && <p className="text-danger">{errors.con_city.message}</p>}
                                                    </Form.Text>
                                                </Form.Group>

                                                <Form.Group className={`mt-2 col-md-12 ${fieldList["pincode"]["hidden"] == "Y" ? "dn" : ""}`} controlId="con_pincode">
                                                    <FloatingLabel controlId="floatingInput" label={`Pincode${fieldList["pincode"]["required"] == "Y" ? "*" : ""}`} className="mb-2" >
                                                        <Form.Control type="text" placeholder="Please enter your email" {...register('con_pincode', { required: { value: fieldList["pincode"]["required"] == "Y" && fieldList["pincode"]["hidden"] == "N" ? true : false, message: 'Pincode is required' } })} />
                                                    </FloatingLabel>
                                                    <Form.Text className="text-muted">
                                                        {errors.con_pincode && <p className="text-danger">{errors.con_pincode.message}</p>}
                                                    </Form.Text>
                                                </Form.Group>


                                                <Form.Group className={`mt-2 col-md-12 ${fieldList["profilePic"]["hidden"] == "Y" ? "dn" : ""}`} controlId="profileimg">
                                                    <FloatingLabel controlId="floatingInput" label={`Profile Image${fieldList["profilePic"]["required"] == "Y" ? "*" : ""}`} className="mb-2" >
                                                        <Form.Control className='profileimg' type="file" {...register('profileimg', { required: { value: fieldList["profilePic"]["required"] == "Y" && fieldList["profilePic"]["hidden"] == "N" ? true : false, message: 'Profile picture is required' } })} />
                                                    </FloatingLabel>
                                                    <Form.Text className="text-muted ">
                                                        {errors.profileimg && <p className="text-danger">{errors.profileimg.message}</p>}
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className={`mt-2 col-md-12 regtags `} controlId="con_tags">
                                                <Form.Label>Company Tags :</Form.Label>
                                                  <MultiSelect
                                                        options={tags}
                                                        value={selectedTag}
                                                        onChange={setSelectedTag}
                                                        labelledBy="Select Company tags"
                                                    />

                                                </Form.Group>           
                                                <div className="mt-4 text-center col-12 ">
                                                    <Button className="btn-lg btnOrange loginBtn btn btn-primary mx-2" onClick={() => generateotp()} disabled={submitloader}>
                                                        Skip
                                                    </Button>
                                                    <Button className="btn-lg btnBlue loginBtn btn btn-primary" type="submit" disabled={submitloader} >
                                                        Submit
                                                    </Button>
                                                </div>
                                                <div className="text-center submitloader mt-3">
                                                    {submitloader ?
                                                        <>
                                                            <Preloader />
                                                        </>
                                                        : ""
                                                    }
                                                </div>
                                            </Form>
                                            :
                                            <Form id="register-form2" className="col-12 row m-0 py-5" onSubmit={handleSubmit(onVerifyOTP)}>
                                                <h2 className='text-center fw-bold py-2'> <span className="orangeColor">Verify it's you</span> </h2>
                                                <p className="text-muted text-center ">
                                                    Please enter the code sent on your <br />
                                                    registered email/mobile number
                                                </p>
                                                <Form.Group className="mb-3" controlId="verifyOTP">
                                                    <Form.Control className='text-center' type="text"  {...register('verifyOTP', {
                                                        required: "OTP number is required"
                                                    })} />
                                                    <Form.Text className="text-muted">
                                                        {errors.verifyOTP && <p className="text-danger">{errors.verifyOTP.message}</p>}
                                                    </Form.Text>

                                                </Form.Group>
                                                <h4 onClick={() => { generateotp() }} className='navyBlueColor text-center text-decoration-underline cursor-pointer'>Resend code</h4>
                                                <div className="mt-4 text-center col-12 ">
                                                    <Button className="btn-lg btnBlue loginBtn btn btn-primary" type="submit" disabled={submitloader} >
                                                        Submit
                                                    </Button>
                                                </div>
                                                <p className='orangeColor text-center py-3'>OTP code is valid for 10 minutes</p>
                                                <div className="text-center submitloader mt-3">
                                                    {submitloader ?
                                                        <>
                                                            <Preloader />
                                                        </>
                                                        : ""
                                                    }
                                                </div>
                                            </Form>
                                        ]
                                    }



                                </div>
                        }
                    </div>
                </div>

            </div>
            <footer className="">
                <div className="col-12 row">
                    <div className="col-12 text-center">
                    © {year} All rights reserved by PureFood Expo.
                    </div>
                </div>

            </footer>
        </div>
    )
}

export default RegisterPage2
