import React from 'react'
import './css/Pricing.css'

export const Pricing = () => {
    const GoToLink = (link) =>{
        window.open(link, "_parent"); 
    }
    return (
        <div className='pricingPage'>
            <div className="header">
                
                <div className="inner-header flex">
                
                    <section>
                    <h3 className="package-title">Stall packages</h3>
                        <div className="pricing pricing-palden">
                            <div className="pricing-item features-item ja-animate" data-animation="move-from-bottom" data-delay="item-0">
                                <div className="pricing-deco">
                                    <svg className="pricing-deco-img" enable-background="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" xmlSpace="preserve" y="0px">
                                        <path className="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"></path>
                                        <path className="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"></path>
                                        <path className="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"></path>
                                        <path className="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"></path>
                                    </svg>
                                    <div className="pricing-price"><span className="pricing-currency"></span>Get Seen</div>
                                    <h3 className="pricing-title">Free</h3>
                                </div>
                                <ul className="pricing-feature-list">
                                    <li className="pricing-feature">Small stall with LED TV and 2 tall banners</li>
                                    <li className="pricing-feature">Social media & web links integrations</li>
                                    <li className="pricing-feature">Whatsapp integration</li>
                                    <li className="pricing-feature">01 online exhibitor pass</li>

                                </ul>
                                {/* <button onClick={() => GoToLink("https://bharatexpo.com/exhibitor-register/b3V3ZnJqPU1RPT0mb2Fiaj1lSFk9Jmdkcj1aRzU1Y1hBPQ==")} className="pricing-action">Get started</button> */}
                            </div>
                            <div className="pricing-item features-item ja-animate pricing__item--featured" data-animation="move-from-bottom" data-delay="item-1">
                                <div className="pricing-deco" style={{ background: "linear-gradient(135deg,#ec7c22,#ec7c22)" }}>
                                    <svg className="pricing-deco-img" enable-background="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" xmlSpace="preserve" y="0px">
                                        <path className="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"></path>
                                        <path className="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"></path>
                                        <path className="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"></path>
                                        <path className="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"></path>
                                    </svg>
                                    <div className="pricing-price"><span className="pricing-currency"></span>Get Attention
                                    </div>
                                    <h3 className="pricing-title">Premium</h3>
                                </div>
                                <ul className="pricing-feature-list">
                                    <li className="pricing-feature">Big stall with LED TV and 2 gif square banners</li>
                                    <li className="pricing-feature">Social media & web links integrations from stall</li>
                                    <li className="pricing-feature">Whatsapp integration</li>
                                    <li className="pricing-feature">Social media & digital promotion</li>
                                    <li className="pricing-feature">Up to 250 Product / listing</li>
                                    <li className="pricing-feature">04 online exhibitor pass</li>
                                    <li className="pricing-feature">Exhibition certificate</li>
                                </ul>
                                <div className="package-price">
                                    
                                    <div className="standard-price">
                                        <h4> Price :<span> 50,000+Taxes </span>/ annum*</h4>
                                    </div>
                                </div>
                                {/* <button onClick={() => GoToLink("https://bharatexpo.com/exhibitor-register/eGxzaXRnPU13PT0mY294dT1ZV3c9Jm1zbj1kWFp2WldnPQ==")} className="pricing-action">Book now</button> */}
                            </div>
                            <div className="pricing-item features-item ja-animate" data-animation="move-from-bottom" data-delay="item-2">
                                <div className="pricing-deco">
                                    <svg className="pricing-deco-img" enable-background="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" xmlSpace="preserve" y="0px">
                                        <path className="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"></path>
                                        <path className="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"></path>
                                        <path className="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"></path>
                                        <path className="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"></path>
                                    </svg>
                                    <div className="pricing-price"><span className="pricing-currency"></span>Get Noticed

                                    </div>
                                    <h3 className="pricing-title">Standard</h3>
                                </div>
                                <ul className="pricing-feature-list">

                                    <li className="pricing-feature">Small stall with LED TV and 2 tall banners</li>
                                    <li className="pricing-feature">Social media & web links integrations</li>
                                    <li className="pricing-feature">Whatsapp integration</li>
                                    <li className="pricing-feature">Up to 100 Product / listing</li>
                                    <li className="pricing-feature">02 online exhibitor pass</li>
                                    <li className="pricing-feature">Exhibition certificate</li>

                                </ul>
                                <div className="package-price">
                                    
                                    <div className="standard-price">
                                        <h4> Price :<span> 24,000+Taxes </span>/ annum*</h4>
                                    </div>
                                </div>
                                {/* <button onClick={() => GoToLink("https://bharatexpo.com/exhibitor-register/YWhpcXltPU1nPT0mZXJ0bz1jR2M9JnZmZz1jMnRwWVhVPQ==")} className="pricing-action">Book now</button> */}
                            </div>
                        </div>
                    </section>
                </div>
                <div>
                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="parallax">
                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    )
}
