import React, { useState, useEffect, useRef } from 'react'
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import axios from 'axios';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { PaymentBox, ToastNotify } from '../Actions';
import { Pageloader } from '../Components/Pageloader';
import serialize from 'form-serialize';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MultiSelect } from "react-multi-select-component";
import { Preloader } from '../Components/Preloader';
import './css/ExeRegisterPage.css';

export const ExeRegisterPage = ({ name, commonData, toast }) => {

    document.title = "Exhibitor Register Page";
    const dispatch = useDispatch();
    const history = useHistory();
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const regPass = useRef({});
    regPass.current = watch("regPass", "");
    const phptoken = useSelector(state => state.phpToken);
    const Receipt = useSelector(state => state.DonePayment);
    const [submitloader, setSubmitLoader] = useState(false);
    const [Designation, setDesignation] = useState([]);
    const [Country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [industry, setindustry] = useState([]);
    const [stallType, setstallType] = useState([])
    const [pageloader, setpageloader] = useState(true);
    useEffect(() => {
        async function getRegisterSettingDetail() {
            let url = commonData.siteurl + '/expo_access_api/getexeregisterfieldlist/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                stall: name
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    if (res.data[0].DATA.stall) {
                        setstallType(res.data[0].DATA.stall);
                    }
                    if (res.data[0].DATA.designation) {
                        setDesignation(res.data[0].DATA.designation);
                    }
                    if (res.data[0].DATA.country) {
                        setCountry(res.data[0].DATA.country);
                    }
                    if (res.data[0].DATA.state) {
                        setState(res.data[0].DATA.state);
                    }
                    if (res.data[0].DATA.industry) {
                        setindustry(res.data[0].DATA.industry);
                    }
                    setpageloader(false)
                } else {
                    toast("Error", res.data[0].MSG, "danger");
                    setpageloader(false)
                }
            }
        }

        getRegisterSettingDetail();

    }, [name])
    //change state as per country
    const changestate = (e) => {

        async function getStates() {
            let url = commonData.siteurl + '/expo_access_api/getstatelist/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                COUNTRY: e.target.value
            });

            if (res) {
                if (res.data[0].STATUS == "SUCCESS") {
                    if (res.data[0].DATA.state) {
                        setState(res.data[0].DATA.state);
                    }

                } else {
                    dispatch(ToastNotify(true, "ERROR", res.data[0].MSG, "danger"));
                    setState([]);
                }
            }
        }

        getStates();
    }
    useEffect(() => {
        setValue("country", "India");
        setValue("state", "GUJARAT");
    }, [])
    const [WizardDwtails, setWizardDwtails] = useState([])
    const [wizardSteps, setwizardSteps] = useState(1)
    const onSubmit = (data) => {
        setSubmitLoader(true);
        async function saveExhibitor() {
            let url = commonData.siteurl + '/expo_access_api/saveExhiRegister/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                data: data,
                stall: name
            });

            if (res) {
                setSubmitLoader(false);
                if (res.data[0].STATUS == "SUCCESS") {
                    toast("SUCCESS", res.data[0].MSG, "success");
                    if (stallType.Support_Stall_Type.price > 0) {
                        setWizardDwtails(res.data[0].DATA)
                        setwizardSteps(2)
                    } else {
                        setWizardDwtails(res.data[0].DATA)
                        setwizardSteps(3)
                        stallCreation(res.data[0].DATA.main, res.data[0].DATA.contact)
                    }

                } else {
                    toast("Error", res.data[0].MSG, "danger");
                    if (res.data[0].DATA) {
                        setWizardDwtails(res.data[0].DATA)
                        setwizardSteps(2)
                    }

                }

            }
        }

        saveExhibitor();
    }
    const loginleft = {
        background: "#fff", //"url(/loginleftimage.jpg)",
        minHeight: window.innerHeight,
        backgroundSize: "100% 100%"
    }
    useEffect(() => {
        if (Receipt.on) {
            setwizardSteps(3)
            stallCreation()
            async function sendinvoice() {

                let url = commonData.siteurl + '/expo_access_api/generateinvoice/';

                const res = await axios.post(url, {
                    AUTHORIZEKEY: commonData.AuthorizeKey,
                    PHPTOKEN: phptoken,
                    contact: WizardDwtails.main,
                    stall: name,
                    asso: WizardDwtails.contact,
                });

                if (res) {
                    console.log(res.data[0].MSG)
                }
            }

            sendinvoice();
        }

    }, [Receipt])

    const stallCreation = (wizard, asso) => {
        async function generatestall() {

            let url = commonData.siteurl + '/expo_access_api/generatestall/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                contact: wizard ? wizard : WizardDwtails.main,
                stall: name,
                asso: asso ? asso : WizardDwtails.contact,
            });

            if (res) {
               // console.log(res)
                if (res.data[0].STATUS == "SUCCESS") {
                    setWizardDwtails(res.data[0].DATA)
                    setwizardSteps(4)
                    toast("SUCCESS", res.data[0].MSG, "success");
                    setSubmitLoader(false);
                }

            }
        }

        generatestall();
    }

    const [compDesp, setcompDesp] = useState("")
    const [selectedTag, setSelectedTag] = useState([]);
    const onSubmitCompDet = (data) => {
        setSubmitLoader(true);
        async function saveExhibitor() {
            // regCompDetail
            if (data.profileimg.length > 0) {
                if (data.profileimg[0].size > 2097152) {
                    dispatch(ToastNotify(true, "ERROR", "Filesize should be less than 2MB", "danger"));
                    setSubmitLoader(false);
                    return false;

                }
                if (!(data.profileimg[0].type === 'image/jpeg' || data.profileimg[0].type === 'image/jpg' || data.profileimg[0].type === 'image/gif' || data.profileimg[0].type === 'image/png' || data.profileimg[0].type === 'image/pjpeg')) {
                    dispatch(ToastNotify(true, "ERROR", "Invalid file type only JPEG/PNG/JPG files allowed", "danger"));
                    setSubmitLoader(false);
                    return false;

                }
            }
            let url = commonData.siteurl + '/expo_access_api/saveExhiCompDetail/';
            const form = document.querySelector('#regCompDetail');

            const obj = serialize(form, { hash: true });
            const fd = new FormData();
            const headers = {
                'content-type': 'multipart/form-data'
            };
            Object.keys(obj).forEach(function (key) {
                fd.append(key, (obj[key]));
            });
            if (data.profileimg.length > 0) {
                fd.append('profileimg', data.profileimg[0], data.profileimg[0].name);
            }
            fd.append("AUTHORIZEKEY", (commonData.AuthorizeKey));
            fd.append("stall", (WizardDwtails.stall));
            fd.append("contact", (WizardDwtails.main));
            fd.append("compDesp", (compDesp));
            fd.append("tags", (JSON.stringify(selectedTag)));
            const res = await axios.post(url, fd, headers);

            if (res) {
                setSubmitLoader(false);
                if (res.data[0].STATUS == "SUCCESS") {
                    setwizardSteps(5)
                }

            }
        }

        saveExhibitor();
    }
    //submit address
    const onSubmitAdd = (datas) => {
        setSubmitLoader(true);
        async function saveExeAddress() {
            let url = commonData.siteurl + '/expo_access_api/saveexeaddress/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                contact: WizardDwtails.main,
                stall: WizardDwtails.stall,
                data: datas
            });

            if (res) {
                setSubmitLoader(false);
                if (res.data[0].STATUS == "SUCCESS") {
                    setwizardSteps(6)
                    toast("SUCCESS", res.data[0].MSG, "success");

                }

            }
        }

        saveExeAddress();
    }
    //submit dociallink
    const onSubmitsocial = (datas) => {
        setSubmitLoader(true);
        async function saveExeSocial() {
            let url = commonData.siteurl + '/expo_access_api/saveExeSocial/';

            const res = await axios.post(url, {
                AUTHORIZEKEY: commonData.AuthorizeKey,
                PHPTOKEN: phptoken,
                contact: WizardDwtails.main,
                data: datas
            });

            if (res) {
                setSubmitLoader(false);
                if (res.data[0].STATUS == "SUCCESS") {
                    setwizardSteps(1)
                    toast("SUCCESS", res.data[0].MSG, "success");

                    history.push("/adminpanel");
                }

            }
        }

        saveExeSocial();
    }
    const d = new Date();
    const year = d.getFullYear();
    return (
        <div className='exeregpage'>
            <div className="col-12 exeregisterBlock2" style={loginleft}>
                <div className="col-12 row login-view ">
                    <div className="col-md-6 leftSide" style={{ background: "url('/images/global/regbg.png')", backgroundSize: 'cover' }}>
                        <div className="col-12 rightBlock text-center" >
                            <div className="centerBlock">
                                <img className="imgLogo img-responsive" src="/images/global/regLogo.png" alt='logo_image' />
                                <div className="reisterRightView text-center py-2">
                                    <h2 className='orangeColor py-2'>Already Registered?</h2>
                                    <div className="col-12">
                                        <Link to="/adminpanel">
                                            <Button className="btn-lg btnOrange loginBtn btn btn-primary" variant="primary" type="submit">
                                                Login
                                            </Button>
                                        </Link>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="col-md-6 rightSide">
                        {
                            pageloader
                                ? <Pageloader />
                                :
                                <div className="exeRegPage">
                                    {!stallType.Support_Stall_Type
                                        ?
                                        <div className="LinkNotProper">
                                            <h2 className='text-center fw-bold py-2'> <span className="orangeColor">Exhibitor</span> <span className="orangeColor">Registration</span></h2>
                                            <h4 className='text-center'>Link is not proper , please contact the administrator.</h4>
                                        </div>
                                        :
                                        <div className="exeWizardBlock py-2">
                                            {wizardSteps == 1
                                                ?
                                                <div className="basicDetailExe">
                                                    <Form id="register-form" className="col-12 row m-0" onSubmit={handleSubmit(onSubmit)}>
                                                        <h2 className='text-center fw-bold py-2'> <span className="orangeColor">Exhibitor</span> <span className="orangeColor">Registration</span></h2>
                                                        <Form.Group className={`mt-2 col-md-12`} controlId="regCompName">
                                                            <FloatingLabel controlId="floatingInput" label="Company Name" className="mb-2" >
                                                                <Form.Control type="text" placeholder="please enter your number" {...register('regCompName', { required: 'Company name is required' })} />
                                                            </FloatingLabel>
                                                            <Form.Text className="text-muted">
                                                                {errors.regCompName && <p className="text-danger">{errors.regCompName.message}</p>}
                                                            </Form.Text>
                                                        </Form.Group>

                                                        <Form.Group className="mt-2 col-md-12" controlId="regName">
                                                            <FloatingLabel controlId="floatingInput" label="Name" className="mb-2" >
                                                                <Form.Control type="text" placeholder="please enter your number" {...register('regName', { required: "Name is required", pattern: { value: /^[A-Za-z ]+$/i, message: 'please enter only text' } })} />
                                                            </FloatingLabel>
                                                            <Form.Text className="text-muted">
                                                                {errors.regName && <p className="text-danger">{errors.regName.message}</p>}
                                                            </Form.Text>
                                                        </Form.Group>

                                                        <Form.Group className="mt-2 col-md-12" controlId="regMobile">
                                                            <FloatingLabel controlId="floatingInput" label="Mobile Number " className="mb-2" >
                                                                <Form.Control type="text" placeholder="please enter your mobile number"
                                                                    {...register('regMobile', {
                                                                        required: "Mobile number is required",
                                                                        maxLength: { value: 13, message: 'Mobile number must be less than 13 characters' },
                                                                        minLength: { value: 6, message: 'Mobile number must be more than 6' },
                                                                        pattern: { value: /^[0-9]+$/i, message: 'please enter only number' }
                                                                    })} />
                                                            </FloatingLabel>
                                                            <Form.Text className="text-muted">
                                                                {errors.regMobile && <p className="text-danger">{errors.regMobile.message}</p>}
                                                            </Form.Text>
                                                        </Form.Group>

                                                        <Form.Group className="mt-2 col-md-12" controlId="regEmail">
                                                            <FloatingLabel controlId="floatingInput" label="Email " className="mb-2" >
                                                                <Form.Control type="email" placeholder="Please enter your email" {...register('regEmail', { required: "Email is required" })} />
                                                            </FloatingLabel>
                                                            <Form.Text className="text-muted">
                                                                {errors.regEmail && <p className="text-danger">{errors.regEmail.message}</p>}
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <Form.Group className="mt-2 col-md-12" controlId="regPass">
                                                            <FloatingLabel controlId="floatingInput" label="Password" className="mb-2" >
                                                                <Form.Control type="password" placeholder="please enter your password"
                                                                    {...register('regPass', {
                                                                        required: "Password number is required"
                                                                    })} />
                                                            </FloatingLabel>
                                                            <Form.Text className="text-muted">
                                                                {errors.regPass && <p className="text-danger">{errors.regPass.message}</p>}
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <Form.Group className="mt-2 col-md-12" controlId="regCPass">
                                                            <FloatingLabel controlId="floatingInput" label="Confirm Password" className="mb-2" >
                                                                <Form.Control type="password" placeholder="please enter your confirm password"
                                                                    {...register('regCPass', {
                                                                        validate: value =>
                                                                            value === regPass.current || "The passwords do not match"
                                                                    })} />
                                                            </FloatingLabel>
                                                            <Form.Text className="text-muted">
                                                                {errors.regCPass && <p className="text-danger">{errors.regCPass.message}</p>}
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <Form.Text>
                                                            By clicking Submit, you agree to our  <Link to="/exhibitor-terms-policy" target="_blank" className='blueColor'>Terms</Link>, <Link to="/privacy-policy" target="_blank" className='blueColor'>Privacy Policy</Link> and <Link to="/cookie-policy" target="_blank" className='blueColor'>Cookie Policy</Link>. You may receive SMS and EMAIL notifications from us.
                                                        </Form.Text>

                                                        <div className="mt-4 text-center col-12 ">
                                                            <Button className="btn-lg btnBlue loginBtn btn btn-primary" type="submit" disabled={submitloader} >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>

                                                </div>
                                                :
                                                [wizardSteps == 2
                                                    ?
                                                    <div className="paymentExe ">
                                                        <div className="stallName">
                                                            <h2 className='fw-bold orangeColor text-center'>Payment Page</h2>
                                                        </div>
                                                        <div className="imgPayment text-center py-3">
                                                            <img src="/images/global/paymentLogo.png" width="150px" />
                                                        </div>
                                                        <div className="basicDetails blueColor text-center py-3">
                                                            <h3>Package Details:</h3>
                                                            <h5>Total Number of Exhibitors: {stallType.Support_Stall_Type.total_contact} </h5>
                                                            <h5>Total Number of Products: {stallType.Support_Stall_Type.total_product}</h5>
                                                        </div>
                                                        <div className="stallPayBtn text-center">
                                                            <button onClick={() => { dispatch(PaymentBox(true, stallType.Support_Stall_Type.price, "INR", WizardDwtails.main, WizardDwtails.type, WizardDwtails.name, WizardDwtails.mobile, WizardDwtails.email, "exhibitorRegister")) }} className='btn btnOrange loginBtn'>Pay</button>
                                                        </div>
                                                    </div>
                                                    :
                                                    [wizardSteps == 3
                                                        ?
                                                        <div className="gernerateStall text-center my-5">
                                                            <h2 className=' fw-bold py-2'> <span className="orangeColor">Generating Stall</span></h2>
                                                            <img src="/images/global/stallLoader.gif" width="300px" />
                                                        </div>
                                                        :
                                                        [wizardSteps == 4
                                                            ?
                                                            <Form id="regCompDetail" className="col-12 row m-0" onSubmit={handleSubmit(onSubmitCompDet)}>
                                                                <h2 className='text-center fw-bold py-2'> <span className="orangeColor">Company Details</span></h2>
                                                                <Form.Group className={`mt-2 col-md-12 `} controlId="profileimg">
                                                                    <Form.Label>Company Logo :</Form.Label>
                                                                    <Form.Control className='profileimg' type="file" {...register('profileimg')} />

                                                                    <Form.Text className="text-muted ">
                                                                        {errors.profileimg && <p className="text-danger">{errors.profileimg.message}</p>}
                                                                    </Form.Text>
                                                                </Form.Group>

                                                                <Form.Group className={`mt-2 col-md-12`} controlId="con_industry">
                                                                    {/* <FloatingLabel controlId="floatingInput" label="Industry Type" className="mb-2" > */}
                                                                    {/* <Form.Select aria-label="Default select example" {...register('con_industry', { required: 'Industry is required' })}>
                                                                            <option value="">Select</option>
                                                                            {industry
                                                                                ?
                                                                                [industry.map((i) => (
                                                                                    <option key={`industry_${i.Support_Stall_Tags.sstag_id}`} value={i.Support_Stall_Tags.sstag_id}>{i.Support_Stall_Tags.name}</option>
                                                                                ))]
                                                                                : ""
                                                                            }
                                                                        </Form.Select> */}
                                                                    <Form.Label>Company Tags :</Form.Label>
                                                                    <MultiSelect
                                                                        options={industry}
                                                                        value={selectedTag}
                                                                        onChange={setSelectedTag}
                                                                        labelledBy="Select"
                                                                    />
                                                                    {/* </FloatingLabel> */}
                                                                    <Form.Text className="text-muted">
                                                                        {errors.con_industry && <p className="text-danger">{errors.con_industry.message}</p>}
                                                                    </Form.Text>
                                                                </Form.Group>
                                                                <Form.Group className="mt-2 col-md-12" controlId="comp-description">
                                                                    <Form.Label>Company Description :</Form.Label>
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={compDesp}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            setcompDesp(data);
                                                                        }}

                                                                    />
                                                                </Form.Group>
                                                                <div className="mt-4 text-center col-12 ">
                                                                    <Button className="btn-lg btnBlue loginBtn btn btn-primary" type="submit" disabled={submitloader} >
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                            </Form>
                                                            :
                                                            [wizardSteps == 5
                                                                ?
                                                                <Form id="register-addform" className="col-12 row m-0" onSubmit={handleSubmit(onSubmitAdd)}>
                                                                    <h2 className='text-center fw-bold py-2'> <span className="orangeColor">Address</span></h2>

                                                                    <Form.Group className={`mt-2 col-md-12 `} controlId="con_add1">
                                                                        <FloatingLabel controlId="floatingInput" label="Address " className="mb-2" >
                                                                            <Form.Control type="text" placeholder="Please enter your email" {...register('con_add1', { required: 'Address is required' })} />
                                                                        </FloatingLabel>
                                                                        <Form.Text className="text-muted">
                                                                            {errors.con_add1 && <p className="text-danger">{errors.con_add1.message}</p>}
                                                                        </Form.Text>
                                                                    </Form.Group>

                                                                    <Form.Group className={`mt-2 col-md-12 `} controlId="con_city">
                                                                        <FloatingLabel controlId="floatingInput" label="City " className="mb-2" >
                                                                            <Form.Control type="text" placeholder="Please enter your email" {...register('con_city', { required: 'City is required' })} />
                                                                        </FloatingLabel>
                                                                        <Form.Text className="text-muted">
                                                                            {errors.con_city && <p className="text-danger">{errors.con_city.message}</p>}
                                                                        </Form.Text>
                                                                    </Form.Group>

                                                                    <Form.Group className={`mt-2 col-md-12 `} controlId="con_pincode">
                                                                        <FloatingLabel controlId="floatingInput" label="Pincode " className="mb-2" >
                                                                            <Form.Control type="text" placeholder="Please enter your email" {...register('con_pincode', { required: 'Pincode is required' })} />
                                                                        </FloatingLabel>
                                                                        <Form.Text className="text-muted">
                                                                            {errors.con_pincode && <p className="text-danger">{errors.con_pincode.message}</p>}
                                                                        </Form.Text>
                                                                    </Form.Group>

                                                                    <Form.Group className={`mt-2 col-md-12 `} controlId="country">
                                                                        <FloatingLabel controlId="floatingInput" label="Country " className="mb-2" >
                                                                            <Form.Select aria-label="Default select example" {...register('country', { required: 'Country is required' })} onChange={(e) => { changestate(e) }}>
                                                                                <option value="">Select</option>
                                                                                {Country
                                                                                    ?
                                                                                    [Country.map((C) => (
                                                                                        <option key={`country_${C.Country.country_code}`} value={C.Country.country_name}>{C.Country.country_name}</option>
                                                                                    ))]
                                                                                    : ""
                                                                                }
                                                                            </Form.Select>
                                                                        </FloatingLabel>
                                                                        <Form.Text className="text-muted">
                                                                            {errors.country && <p className="text-danger">{errors.country.message}</p>}
                                                                        </Form.Text>
                                                                    </Form.Group>

                                                                    <Form.Group className={`mt-2 col-md-12 `} controlId="state">
                                                                        <FloatingLabel controlId="floatingInput" label="State " className="mb-2" >
                                                                            <Form.Select aria-label="Default select example" {...register('state', { required: 'State is required' })}>
                                                                                <option value="">Select</option>
                                                                                {State
                                                                                    ?
                                                                                    [State.map((S) => (
                                                                                        <option key={`state_${S.State.state_name}`} value={S.State.state_name}>{S.State.state_name}</option>
                                                                                    ))]
                                                                                    : ""
                                                                                }
                                                                            </Form.Select>
                                                                        </FloatingLabel>
                                                                        <Form.Text className="text-muted">
                                                                            {errors.state && <p className="text-danger">{errors.state.message}</p>}
                                                                        </Form.Text>
                                                                    </Form.Group>

                                                                    <div className="mt-4 text-center col-12 ">
                                                                        <Button className="btn-lg btnOrange loginBtn btn btn-primary mx-2" onClick={() => setwizardSteps(6)}  >
                                                                            Skip
                                                                        </Button>
                                                                        <Button className="btn-lg btnBlue loginBtn btn btn-primary" type="submit" disabled={submitloader} >
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </Form>
                                                                :
                                                                [wizardSteps == 6
                                                                    ?
                                                                    <Form id="register-socialform" className="col-12 row m-0" onSubmit={handleSubmit(onSubmitsocial)}>
                                                                        <h2 className='text-center fw-bold py-2'> <span className="orangeColor">Social Links</span></h2>
                                                                        <Form.Group className={`mt-2 col-md-12`} controlId="website">
                                                                            <FloatingLabel controlId="floatingInput" label="Website Link" className="mb-2" >
                                                                                <Form.Control type="text" placeholder="Please enter your link" {...register('website')} />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group className={`mt-2 col-md-12`} controlId="instagram">
                                                                            <FloatingLabel controlId="floatingInput" label="Instagram Link" className="mb-2" >
                                                                                <Form.Control type="text" placeholder="Please enter your link" {...register('instagram')} />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group className={`mt-2 col-md-12`} controlId="facebook">
                                                                            <FloatingLabel controlId="floatingInput" label="Facebook Link" className="mb-2" >
                                                                                <Form.Control type="text" placeholder="Please enter your link" {...register('facebook')} />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group className={`mt-2 col-md-12`} controlId="youtube">
                                                                            <FloatingLabel controlId="floatingInput" label="YouTube Link" className="mb-2" >
                                                                                <Form.Control type="text" placeholder="Please enter your link" {...register('youtube')} />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group className={`mt-2 col-md-12`} controlId="linkedin">
                                                                            <FloatingLabel controlId="floatingInput" label="LinkedIn Link" className="mb-2" >
                                                                                <Form.Control type="text" placeholder="Please enter your link" {...register('linkedin')} />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group className={`mt-2 col-md-12`} controlId="twitter">
                                                                            <FloatingLabel controlId="floatingInput" label="Twitter Link" className="mb-2" >
                                                                                <Form.Control type="text" placeholder="Please enter your link" {...register('twitter')} />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <div className="mt-4 text-center col-12 ">
                                                                            <Button className="btn-lg btnOrange loginBtn btn btn-primary mx-2" onClick={() => history.push("/adminpanel")}  >
                                                                                Skip
                                                                            </Button>
                                                                            <Button className="btn-lg btnBlue loginBtn btn btn-primary" type="submit" disabled={submitloader} >
                                                                                Submit
                                                                            </Button>
                                                                        </div>
                                                                    </Form>
                                                                    : ""
                                                                ]
                                                            ]
                                                        ]
                                                    ]
                                                ]

                                            }
                                            <div className="text-center submitloader mt-3">
                                                {submitloader ?
                                                    <>
                                                        <Preloader />
                                                    </>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                        }
                    </div>
                </div>
           
            </div>
            <footer className="">
                    <div className="col-12 row">
                        <div className="col-md-8">
                        © {year} All right reserved by PureFood Expo. a vertical of horus tech solution pvt ltd.
                        </div>
                        <div className="col-md-4">
                            <a href="https://h-expo.online/" target="_blank">Powered by H-EXPO</a>
                        </div>
                    </div>

                </footer>
        </div>
    )
}
