import React from 'react'
import 'font-awesome/css/font-awesome.min.css';
import { Link } from 'react-router-dom';
export const Footer = () => {
    const d = new Date();
    const year = d.getFullYear();
    return (
        <div className="main-footer">
            <div className="row col-md-12 footer">

                <div className="widget-1 col-md-3 py-4">
                <Link to = "/"><img src="/images/global/pureexpo_logo.png" width="150px" height="60px" alt="PureFood Expo" className="bharat-logo my-4" /> </Link>
                </div>

                <div className="widget-1  col-md-6 py-4">
                    <div className="links-footer my-4">
                        <ul>
                            {/* <li><Link to = "/register">Register </Link></li> */}
                            <li><Link to = "/adminpanel">Exhibitor</Link></li>
                            <li><Link to = "/login">Visitor</Link></li>
                            <li><Link to = "/whyus">Why us?</Link></li>
                        </ul>
                    </div>
                    <div className="links-footer my-4">
                        <ul>
                            <li><Link to = "/bookstall">Book a stall</Link></li>
                            <li><Link to = "/advertising">Advertising</Link></li>
                            <li><Link to = "/about">About</Link></li>
                        </ul>
                    </div>
                    <div className="links-footer my-4">
                        <ul>
                            <li><Link to = "/contact">Contact</Link></li>
                            <li><Link to = "/terms-policy">Terms Policy</Link></li>
                            <li><Link to = "/privacy-policy">Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="widget-1  col-md-3 py-4">
                    <div className="social">
                        <h5>Follow us on</h5>
                        <div className="youtubelinks my-1">
                            <a class="youtube-image" href="https://www.youtube.com/channel/UCmZPwCQ7RU41yDrlO-5VS0Q" target="_blank" rel="noopener noreferrer"><i className="fa fa fa-youtube-square fa-2x" aria-hidden="true"></i></a>
                            <a class="instagram-image" href="https://www.instagram.com/bharatexpo/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram fa-2x" aria-hidden="true"></i></a>
                            <a class="facebook-image" href="https://www.facebook.com/bharatexpo/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>
                        </div>
                    </div>
                    <div className="social1">
                        <div className="youtubelinks my-1">
                            <a class="youtube-image" href="https://twitter.com/BharatExpo" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter-square fa-2x" aria-hidden="true"></i></a>
                            <a class="instagram-image" href="https://in.pinterest.com/thebharatexpo/" target="_blank" rel="noopener noreferrer"><i className="fa fa-pinterest-square fa-2x" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>


            </div>
            <div className="footer bg-dark text-light">
                <div className="col-md-12 footer">

                    <p className="text-center copyright text-capitalize">
                    © {year} All rights reserved by PureFood Expo.
                    </p>
                </div>
            </div>
        </div>
    )
}
